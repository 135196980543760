<template>
  <div class="">
    <b-row>
      <b-col lg="8" cols="12" order="1" order-lg="2">
        <b-overlay :show="loading">
          <b-card>
            <div class="align-items-center mb-1">
              <h3>Ganti Kata Sandi</h3>
            </div>
            <validation-observer ref="resetPassword" #default="{ invalid }">
              <b-form class="auth-reset-password-form mt-2" method="POST" @submit.prevent="submit">
                <!-- password lama -->
                <b-form-group label="Kata Sandi Lama" label-for="kata_sandi_lama">
                  <validation-provider #default="{ errors }" name="Kata Sandi Lama" vid="kata_sandi_lama" rules="required">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input id="kata_sandi_lama" v-model="old_password" :type="passwordFieldTypeL" :state="errors.length > 0 ? false : null" class="form-control-merge" name="kata_sandi_lama" placeholder="Masukkan Kata Sandi Lama" />
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer" :icon="passwordToggleIconL" @click="togglePasswordVisibilityL" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <hr />
                <!-- password baru-->
                <b-form-group label="Kata Sandi Baru" label-for="kata_sandi_baru">
                  <validation-provider #default="{ errors }" name="Kata Sandi Baru" vid="kata_sandi_baru" rules="required|min:8">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input id="kata_sandi_baru" v-model="new_password" :type="passwordFieldTypeB" :state="errors.length > 0 ? false : null" class="form-control-merge" name="kata_sandi_baru" placeholder="Masukkan Kata Sandi Baru" />
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer" :icon="passwordToggleIconB" @click="togglePasswordVisibilityB" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- konfirmasi password baru -->
                <b-form-group label-for="ckata_sandi_baru" label="Konfirmasi Kata Sandi Baru">
                  <validation-provider #default="{ errors }" name="Konfirmasi Kata Sandi Baru" rules="required|confirmed:kata_sandi_baru">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input
                        id="ckata_sandi_baru"
                        v-model="cNew_password"
                        :type="passwordFieldTypeK"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false : null"
                        name="ckata_sandi_baru"
                        placeholder="Konfirmasi Kata Sandi Baru"
                      />
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer" :icon="passwordToggleIconK" @click="togglePasswordVisibilityK" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit button -->
                <b-button block type="submit" :disabled="invalid" variant="primary"> Simpan </b-button>
              </b-form>
            </validation-observer>

            <!-- <b-button class="mt-2" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="md" variant="primary" block> Simpan </b-button> -->
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BOverlay, BCard, BCardText, BRow, BCol, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend } from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      passwordFieldTypeL: "password",
      passwordFieldTypeB: "password",
      passwordFieldTypeK: "password",
      old_password: "",
      new_password: "",
      cNew_password: "",
      loading: false,
      required,
    };
  },
  methods: {
    togglePasswordVisibilityL() {
      this.passwordFieldTypeL = this.passwordFieldTypeL === "password" ? "text" : "password";
    },
    togglePasswordVisibilityB() {
      this.passwordFieldTypeB = this.passwordFieldTypeB === "password" ? "text" : "password";
    },
    togglePasswordVisibilityK() {
      this.passwordFieldTypeK = this.passwordFieldTypeK === "password" ? "text" : "password";
    },
    submit() {
      this.$refs.resetPassword.validate().then((success) => {
        if (success) {
          this.loading = true;
          const payload = {
            old_password: this.old_password,
            new_password: this.new_password,
          };
          this.$store
            .dispatch("auth/updatePassword", payload)
            .then((res) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Berhasil!`,
                  icon: "CheckIcon",
                  variant: "success",
                  text: "Kata Sandi Berhasil diubah",
                },
              });
              this.$router.go();
            })
            .catch((error) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error `,
                  icon: "XIcon",
                  variant: "danger",
                  text: error.response.data.message,
                },
              });
            });
        }
      });
    },
  },
  components: {
    BOverlay,
    BCard,
    BCardText,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIconL() {
      return this.passwordFieldTypeL === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconB() {
      return this.passwordFieldTypeB === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconK() {
      return this.passwordFieldTypeK === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
};
</script>

<style></style>
