<template>
  <div class="">
    <b-tabs vertical nav-wrapper-class="nav-vertical">
      <b-tab active title="Data Pribadi">
        <DataPribadi />
      </b-tab>
      <b-tab title="Ganti Kata Sandi">
        <GantiSandi />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BCardText } from "bootstrap-vue";
import DataPribadi from "./components/DataPribadi.vue";
import GantiSandi from "./components/GantiSandi.vue";

export default {
  components: {
    BCardText,
    BTabs,
    BTab,
    DataPribadi,
    GantiSandi,
  },
};
</script>

<style></style>
