<template>
  <div class="">
    <b-row>
      <b-col lg="8" cols="12" order="1" order-lg="2">
        <b-overlay :show="loading">
          <b-card>
            <div class="align-items-center mb-1">
              <h3>Data Pribadi</h3>
              <div class="mt-2 text-center">
                <b-avatar
                  button
                  size="90"
                  :src="viewAvatar"
                  class="align-baseline img-fluid"
                  @click="$refs.fileInput.click()"
                />
                <input
                  style="display: none"
                  ref="fileInput"
                  type="file"
                  @change="fileSelected"
                  enctype="multipart/form-data"
                  accept="image/*"
                />
              </div>

              <hr class="mt-2" />
            </div>

            <validation-observer ref="profil" #default="{ invalid }">
              <b-form class="profil mt-2" @submit.prevent="simpan">
                <b-form-group label="Nama Lengkap" label-for="nama_lengkap">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Lengkap"
                    vid="nama_lengkap"
                    rules="required"
                  >
                    <b-form-input
                      id="nama_lengkap"
                      v-model="form.nama_lengkap"
                      :state="errors.length > 0 ? false : null"
                      name="nama_lengkap"
                      placeholder="Masukkan Nama Lengkap"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Asal Sekolah" label-for="asal_sekolah">
                  <validation-provider
                    #default="{ errors }"
                    name="Asal Sekolah"
                    vid="asal_sekolah"
                    rules="required"
                  >
                    <!-- <b-form-input id="asal_sekolah" v-model="form.asal_sekolah" :state="errors.length > 0 ? false : null" name="asal_sekolah" placeholder="Masukkan Asal Sekolah" /> -->

                    <v-select
                      label="nama_sekolah"
                      v-model="form.asal_sekolah"
                      :options="optionsSekolah"
                      :state="errors.length > 0 ? false : null"
                      name="asal_sekolah"
                      placeholder="Pilih"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Asal Kota" label-for="asal_kota">
                  <validation-provider
                    #default="{ errors }"
                    name="Asal Kota"
                    vid="asal_kota"
                    rules="required"
                  >
                    <v-select
                      label="nama_kabupaten"
                      v-model="form.asal_kota"
                      :options="options"
                      :state="errors.length > 0 ? false : null"
                      name="asal_kota"
                      placeholder="Pilih"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <hr />
                <b-form-group label="Username" label-for="username">
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    vid="username"
                    rules="required|alpha-dash"
                  >
                    <b-form-input
                      id="username"
                      v-model="form.username"
                      :state="errors.length > 0 ? false : null"
                      name="username"
                      placeholder="Masukkan username"
                      readonly
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Email Kamu" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email Kamu"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="form.email"
                      :state="errors.length > 0 ? false : null"
                      name="email"
                      placeholder="Masukkan email kamu"
                      readonly
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Nomer Telepon/Whatsapp"
                  label-for="no_telp"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nomer Telepon/Whatsapp"
                    vid="no_telp"
                    rules="required|integer|min:11"
                  >
                    <b-form-input
                      id="no_telp"
                      v-model="form.no_telp"
                      :state="errors.length > 0 ? false : null"
                      name="no_telp"
                      placeholder="Masukkan nomor telepon"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <hr />
                <b-form-group label="Tanggal Lahir" label-for="tgl_lahir">
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggal Lahir"
                    vid="tgl_lahir"
                    rules="required"
                  >
                    <flat-pickr
                      id="tgl_lahir"
                      v-model="form.tgl_lahir"
                      :state="errors.length > 0 ? false : null"
                      name="tgl_lahir"
                      class="form-control"
                      :config="{
                        altInput: true,
                        altFormat: 'd-m-Y',
                        dateFormat: 'Y-m-d',
                      }"
                      placeholder="Masukkan tanggal lahir"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <hr />
                <b-form-group label="Nama Ayah" label-for="nama_ayah">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Ayah"
                    vid="nama_ayah"
                    rules="required"
                  >
                    <b-form-input
                      id="nama_ayah"
                      v-model="form.nama_ayah"
                      :state="errors.length > 0 ? false : null"
                      name="nama_ayah"
                      placeholder="Masukkan nama ayah"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Nomer Telepon/Whatsapp Ayah"
                  label-for="no_telp_ayah"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nomer Telepon/Whatsapp Ayah"
                    vid="no_telp_ayah"
                    rules="required|integer|min:11"
                  >
                    <b-form-input
                      id="no_telp_ayah"
                      v-model="form.no_telp_ayah"
                      :state="errors.length > 0 ? false : null"
                      name="no_telp_ayah"
                      placeholder="Masukkan nomor telepon ayah"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Profesi Ayah" label-for="profesi_ayah">
                  <validation-provider
                    #default="{ errors }"
                    name="Profesi Ayah"
                    vid="profesi_ayah"
                    rules="required"
                  >
                    <b-form-input
                      id="profesi_ayah"
                      v-model="form.profesi_ayah"
                      :state="errors.length > 0 ? false : null"
                      name="profesi_ayah"
                      placeholder="Masukkan profesi ayah"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <hr />
                <b-form-group label="Nama Ibu" label-for="nama_ibu">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Ibu"
                    vid="nama_ibu"
                    rules="required"
                  >
                    <b-form-input
                      id="nama_ibu"
                      v-model="form.nama_ibu"
                      :state="errors.length > 0 ? false : null"
                      name="nama_ibu"
                      placeholder="Masukkan nama ibu"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Nomer Telepon/Whatsapp ibu"
                  label-for="no_telp_ibu"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nomer Telepon/Whatsapp ibu"
                    vid="no_telp_ibu"
                    rules="required|integer|min:11"
                  >
                    <b-form-input
                      id="no_telp_ibu"
                      v-model="form.no_telp_ibu"
                      :state="errors.length > 0 ? false : null"
                      name="no_telp_ibu"
                      placeholder="Masukkan nomor telepon ibu"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Profesi Ibu" label-for="profesi_ibu">
                  <validation-provider
                    #default="{ errors }"
                    name="Profesi Ibu"
                    vid="profesi_ibu"
                    rules="required"
                  >
                    <b-form-input
                      id="profesi_ibu"
                      v-model="form.profesi_ibu"
                      :state="errors.length > 0 ? false : null"
                      name="profesi_ibu"
                      placeholder="Masukkan profesi ibu"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-button
                  type="submit"
                  class="mt-2"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  Simpan
                </b-button>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BOverlay,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BAvatarGroup,
  BForm,
  BFormTextarea,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  VBTooltip,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      selected: null,
      profile: [],
      form: {
        nama_lengkap: "",
        asal_sekolah: "",
        asal_kota: null,
        tgl_lahir: null,
        username: "",
        email: "",
        no_telp: "",
        nama_ayah: "",
        no_telp_ayah: "",
        profesi_ayah: "",
        nama_ibu: "",
        no_telp_ibu: "",
        profesi_ibu: "",
        photo: null,
      },
      photo: null,
      selectedFile: null,
      options: [],
      optionsSekolah: [],
      loading: false,
      required,
      email,
    };
  },
  components: {
    BOverlay,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BAvatarGroup,
    BForm,
    BFormTextarea,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem("userData"));
    },
    viewAvatar() {
      if (!this.selectedFile && !this.photo) {
        return null;
      }
      if (this.selectedFile) {
        return this.selectedFile;
      }
      if (this.photo) {
        return this.apiFile + this.photo;
      }
    },
  },
  methods: {
    fileSelected(evt) {
      evt.preventDefault();
      this.form.photo = evt.target.files[0];
      this.selectedFile = URL.createObjectURL(this.form.photo);
    },

    simpan() {
      this.$refs.profil.validate().then((success) => {
        if (success) {
          this.loading = true;
          let payload = {
            name: this.form.nama_lengkap,
            no_telp: this.form.no_telp,
            birth_date: this.form.tgl_lahir,
            school_origin: this.form.asal_sekolah.id,
            city_id: this.form.asal_kota.id,
            father_name: this.form.nama_ayah,
            father_no_telp: this.form.no_telp_ayah,
            father_profession: this.form.profesi_ayah,
            mother_name: this.form.nama_ibu,
            mother_no_telp: this.form.no_telp_ibu,
            mother_profession: this.form.profesi_ibu,
          };
          this.$store
            .dispatch("auth/updateProfile", payload)
            .then((res) => {
              if (this.form.photo != null) {
                const fd = new FormData();
                fd.append("user_id", this.userData.id);
                fd.append("photo", this.form.photo);
                this.$store
                  .dispatch("auth/updateFotoProfile", fd)
                  .then((res) => {
                    this.loading = false;
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Berhasil `,
                        icon: "CheckIcon",
                        variant: "success",
                        text: "Data Pribadi berhasil diperbarui",
                      },
                    });
                    this.loadUserData();
                    setTimeout(function () {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((err) => {
                    this.loading = false;
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Error `,
                        icon: "XIcon",
                        variant: "danger",
                        text: err.response.data.message.photo,
                      },
                    });
                  });
              } else {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Berhasil `,
                    icon: "CheckIcon",
                    variant: "success",
                    text: "Data Pribadi berhasil diperbarui",
                  },
                });
                this.loadUserData();
                setTimeout(function () {
                  window.location.reload();
                }, 1000);
              }
            })
            .catch((err) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error `,
                  icon: "XIcon",
                  variant: "danger",
                  text: err.response.data.message,
                },
              });
            });
        }
      });
    },

    getKabKot() {
      this.loading = true;
      this.$store
        .dispatch("kabupaten_kota/index")
        .then((res) => {
          this.loading = false;
          this.options = res.data.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    getSekolah() {
      this.loading = true;
      this.$store
        .dispatch("sekolah/index")
        .then((res) => {
          this.loading = false;
          this.optionsSekolah = res.data.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },

    getProfile() {
      this.loading = true;
      this.$store
        .dispatch("auth/getProfile")
        .then((res) => {
          this.loading = false;
          this.profile = res.data.data;
          this.form.nama_lengkap = this.profile.name;
          this.form.username = this.profile.username;
          this.form.email = this.profile.email;
          this.form.no_telp = this.profile.no_telp;
          if (this.profile.photo != null) {
            this.photo = this.profile.photo.file;
          }
          if (this.profile.detail != null) {
            this.form.tgl_lahir = this.profile.detail.birth_date;
            this.form.nama_ayah = this.profile.detail.father_name;
            this.form.no_telp_ayah = this.profile.detail.father_no_telp;
            this.form.profesi_ayah = this.profile.detail.father_profession;
            this.form.nama_ibu = this.profile.detail.mother_name;
            this.form.no_telp_ibu = this.profile.detail.mother_no_telp;
            this.form.profesi_ibu = this.profile.detail.mother_profession;
            this.form.asal_sekolah =
              this.profile.detail.school_origin_info.nama_sekolah;

            if (this.profile.detail.city_id) {
              this.$store
                .dispatch("kabupaten_kota/index")
                .then((res) => {
                  let kabKot = res.data.data;
                  let filter = kabKot.filter((res) => {
                    return res.id == this.profile.detail.city_id;
                  });
                  this.form.asal_kota = filter[0].nama_kabupaten;
                })
                .catch((err) => {
                  this.loading = false;
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Error `,
                      icon: "XIcon",
                      variant: "danger",
                      text: err.response.data.message,
                    },
                  });
                });
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    loadUserData() {
      this.loading = true;
      this.$store
        .dispatch("auth/getProfile")
        .then((res) => {
          this.loading = false;
          localStorage.setItem("userData", JSON.stringify(res.data.data));
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
  },

  mounted() {
    this.getProfile();
    this.getKabKot();
    this.getSekolah();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
